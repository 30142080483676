.visually-hidden{
    display: none;
}
 
.page-link{
    border-radius: $border-radius-extra-small;
}

 .pagination > li > a
 {
     background-color: white;
     color: $primary-color;
 }

 .page-item.active .page-link{
    color: white;
    background-color: $primary-color;
    border-color: $primary-color;
 }
 
 .pagination > li > a:focus,
 .pagination > li > a:hover,
 .pagination > li > span:focus,
 .pagination > li > span:hover
 {
     color: $primary-color;
     background-color: #eee;
     border-color: #ddd;
 }
 
 .pagination > .active > a
 {
     color: white;
     background-color: $primary-color;
     border: solid 1px $primary-color;
 }
 
 .pagination > .active > a:hover
 {
     background-color: $primary-color;
     border: solid 1px $primary-color;
 }