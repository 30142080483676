// Toast
.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
  
    .ngx-toastr {
      position: relative;
      overflow: hidden;
      margin: 10px ;
      padding: 0.75rem .75rem 0.25rem 10px;
      width:500px !important;
      background-position: 15px center;
      background-repeat: no-repeat;
      background-size: 24px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
      pointer-events: auto;
  
      .toast-title {
        font-weight: bold;
      }
      .toast-message {
        word-wrap: break-word;
        width: 100%;
      }
      .toast-message a:hover {
        text-decoration: underline;
      }
      .toast-close-button {
        position: relative;
        right: -0.3em;
        top: -0.3em;
        float: right;
        font-size: 20px;
        font-weight: bold;
        color: inherit;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;
        background: transparent;
        border: 0;
        padding: 0;
      }
      .toast-progress {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        background-color: #000000;
        opacity: 0.3;
      }
      .toast-close-button:hover,
      .toast-close-button:focus {
        color: #000000;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.75;
      }
    }
    .ngx-toastr:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      opacity: 1;
      cursor: pointer;
    }
  }

.toast-container{
    width: 100%;
  
    .toast-close-button {
      font-weight: 300;
      text-shadow: none;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: 4px;
      width: 25px;
      height: 25px;
    }
}